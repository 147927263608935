import { render, staticRenderFns } from "./ClassGrade.vue?vue&type=template&id=5fea5dcd&scoped=true"
import script from "./ClassGrade.vue?vue&type=script&lang=js"
export * from "./ClassGrade.vue?vue&type=script&lang=js"
import style0 from "./ClassGrade.vue?vue&type=style&index=0&id=5fea5dcd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fea5dcd",
  null
  
)

export default component.exports